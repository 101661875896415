/* InvoiceForm.css */
*
{
    font-family: Quicksand;
}

/* Base styling for the form */
.invoice-form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 50px auto;
    font-weight: bold;
    padding: 30px;
    border-radius: 15px;
    background: linear-gradient(145deg, #e4e4e4, #ffffff);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
}

.h2-invoice {
    text-align: center;
}

/* Title styling */
.invoice-title {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

/* Label styling */
.invoice-label {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 16px;
    color: black;
}

/* Input styling */
.invoice-input {
    width: 80%;
    padding: 12px 15px;
    margin-top: 8px;
    border: 1px solid #ddd;
    font-weight: bold;
    font-size: 18px;
    border-radius: 5px;
}

/* Add button styling */
.invoice-add-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

/* Invoice preview styling */
.invoice-preview {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 15px;
    background: #f9f9f9;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ddd;
    padding-bottom: 20px;
    position: relative;
}

/* Logo positioning */
.logo-left {
    width: 100px;
    height: auto;
}

.invoice-logo {
    width: 120px;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.logo-right {
    width: 100px;
    height: auto;
}

/* Table styling */
.invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.invoice-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

/* Button styling */
.invoice-button {
    padding: 10px 20px;
    background-color: blue;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    border: none;
    width: 85%;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.ttt {
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 5px;
}

/* Print media query */
@media print {
    .invoice-button {
      display: none;
    }
}

/* Print.css */
@media print {
    .invoice-container {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .invoice-preview {
      border: none;
      box-shadow: none;
    }
}
