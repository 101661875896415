.navbar {
    display: flex;
    justify-content: space-between; /* Distribute space between items */
    align-items: center;
    padding: 10px 20px;
    background-color: blue;
    color: wheat;
  }
  
  .navbar-logo {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Ensure the navbar takes up the full width */
    align-items: center;
  }
  
  .logo {
    height: 100px; /* Adjust logo size as needed */
  }
  
  .invoice-logo {
    height: 100px; /* Adjust invoice logo size as needed */
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* Center the logo in the middle */
  }
  